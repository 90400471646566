@import 'src/styles/colors';

.recap {
  text-align: left;
  margin-top: 80px;

  .recap-text {
    margin-top: 50px;
  }

  .recap-pretitle {
    color: $text-red-color;
    text-transform: uppercase;
    font-weight: 800;
  }

  .form-title {
    margin-top: 60px;
    margin-bottom: 20px;
  }

  .image-col {
    img {
      max-width: 100%;
    }
  }

  @media (max-width: 768px) {
    .image-col {
      img {
        max-width: 70%;
      }
      order: -1;
    }

    text-align: center;
  }
}
