@import "src/styles/colors";

.preview-sm {
  text-align: center;

  .preview-sm-item {
    margin-top: 100px;

    &:nth-child(odd) {
      .circle-content {
        background-color: $orange;
        color: $bg-color;
        border: 0;
        box-shadow: 0px 0px 50px -14px #FFCB57;
      }
    }

    &:nth-child(even) {
      .circle-content {
        background-color: $btn-primary-color;
        color: $text-color;
        border: 0;
      }
    }
  }

  .align-helper {
    display: block;
  }

  $item-size: 64px;

  .align-helper {
    width: $item-size;
    height: $item-size;
    margin: 20px auto;
  }

  .circle {
    width: 100%;
    height: 100%;

    .circle-content {
      color: $bg-color;
    }
  }

  .app-preview-wrapper {
    text-align: center;
    margin: 40px auto 0;
  }
}

@media (min-width: 990px) {
  .preview-sm {
    display: none;
  }
}
