@import "colors";

body {
  font-family: 'Nunito', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg-color;
  color: $text-color;
}

@media (min-width: 768px) {
  body {
    background-image: url("../resources/bgImage.png");
    background-repeat: no-repeat;
    background-position: right top;
    background-size: initial;
    background-position-y: -20%;
  }
}

h1 {
  font-style: normal;
  font-weight: 900;
  font-size: 3.5em;
  letter-spacing: -0.5px;
}

h2 {
  font-weight: bold;
  font-size: 42px;
  line-height: 41px;
  letter-spacing: 0.374px;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 32px;
  letter-spacing: 0.374px;
}

h4 {
  font-weight: bold;
  font-size: 1.5em;
}

small {
  font-size: 1.5em;
  line-height: 32px;
  letter-spacing: 0.374px;
  color: $text-muted-color;
}

.form-control {
  font-size: 1.0625em;
  min-height: 64px;
}

p {
  font-size: 1.5em;
}

@media (max-width: 768px) {
  body {
    text-align: center;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.7em;
    line-height: 1.7em;
  }

  h3 {
    font-size: 1.5em;
    line-height: 1.5em;
  }

  h4 {
    font-size: 1.0625em
  }

  p {
    font-size: 1.2em;
  }

  small {
    font-size: 1.0625em;
    line-height: 1.5em;
  }
}
