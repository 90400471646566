.App {
    text-align: center;
    padding-top: 80px;
}

@media (min-width: 768px) {

  .App {
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media (max-width: 768px) {
  .App {
    padding-top: 20px;
  }
}