@import 'src/styles/colors';

.footer {
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
  }

  .row {
    margin-left: 0;
  }

  h4 {
    margin-bottom: 30px;
  }

  margin-top: 80px;
  margin-bottom: 30px;

  $item-size: 60px;
  .social-link {
    margin-right: 30px;

    height: $item-size;
    width: $item-size;
    border-radius: 50%;

    background-color: $social-link-bg;

    display: inline-block;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: $social-link-bg-hover;
    }
  }

  .align-helper {
    width: $item-size;
    height: $item-size;
    vertical-align: middle;
    text-align: center;
    display: table-cell;
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
  }

  .store-badges {
    display: inline-block;
    margin-left: 60px;
  }

  @media (max-width: 768px) {
    .store-badges {
      display: block;
      margin: auto;
      margin-top: 30px;
    }
  }
}
