$bg-color: #000000;
$border-color: #2C2E32;

$orange: #FFCB57;

$text-color: #FFFFFF;
$text-muted-color: rgba(255, 255, 255, 0.6);
$text-muted-color-hover: rgba(255, 255, 255, 0.5);
$text-red-color: #FF5E36;

$btn-primary-color: #0021B9;
$input-grey-bg: #2C2E32;

$social-link-bg: #2C2E32;
$social-link-bg-hover: #2C2E329F;

$theme-colors: (
        "primary": $btn-primary-color,
        "danger": $text-red-color
);
