@import "src/styles/colors";

.consent-container {
    margin-top: 20px;

    a {
        color: $text-muted-color;

        &:hover {
            color: $text-muted-color-hover;
        }
    }
}