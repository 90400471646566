@import 'src/styles/colors';

.intro {
  text-align: left;

  .overview-text {
    margin-top: 20px;
  }

  .beta {
    text-transform: uppercase;
    color: $text-red-color;
    vertical-align: super;
    font-size: 14px;
    font-weight: bold;
  }

  .intro-title {
    margin-bottom: 50px;
  }

  .intro-subtitle {
    margin-bottom: 20px;
  }

  $app-width: 305px;
  $frame-width: 9.5px;
  $frame-height: 7px;

  .app-preview {
    width: $app-width;
    position: relative;

    .app-preview-content {
      position: absolute;
      top: $frame-height;
      left: $frame-width;
      width: $app-width - 2 * $frame-width;
      z-index: -1;
    }
  }

  @keyframes mouse-scroll {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }

  .scroll-indicator {
    padding-top: 75px;

    img {
      display: block;
      margin: 10px;
      animation: mouse-scroll 2.5s infinite;
      animation-timing-function: linear;
    }

    @for $i from 1 through 3 {
      img:nth-child(#{$i}) {
        animation-delay: #{$i * 450}ms;
      }
    }
  }

  .store-badges {
    margin-top: 60px;
  }

  @media (max-width: 768px) {
    text-align: center;

    .intro-title {
      text-align: center;

      img {
        transform: scale(0.7);
        margin-right: -15px;
      }
    }

    .app-preview {
      margin: 40px auto 0;
    }

    .scroll-indicator {
      display: none;
    }
  }
}
