
.plans {
  margin-top: 180px;
  text-align: center;

  .intro {
    text-align: center;
    width: 80%;
    margin: auto;

    h3 {
      padding: 10px 0;
    }

    small {
      font-size: 1.1em;
    }
  }

  .items {
    margin-top: 100px;

    .item {
      border: 1px solid #7A8492;
      border-radius: 24px;
      padding-top: 25px;

      img {
        height: 50px;
      }

      p {
        margin-top: 20px;
        font-weight: 600;
        font-size: 1.3em;
        line-height: 32px;
      }
    }
  }

  @media (max-width: 768px) {
    margin-top: 30px;
    
    .items {
      margin-top: 50px;

      .item {
        margin-top: 15px;
      }
    }
  }
}
