@import 'src/styles/colors';

// emailForm input
.email-form {
  .email-error {
    font-size: 0.8em;
    color: $text-red-color;
  }

  .form-control {
    min-height: 64px;
  }

  .btn {
    min-height: 64px;
    font-size: 1.25em;
    font-weight: bold;
  }

  .alert {
    margin-left: 15px;
  }

  @media (max-width: 991px) {
    .btn {
      margin-top: 10px;
    }
  }
  @media (max-width: 768px) {
    .form-control {
      width: 100%;
    }
    .btn {
      width: 100%;
      padding: 12px 10px;
    }
  }
}
