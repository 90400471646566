@import 'src/styles/colors';

$device-height: 549px;
$device-width: 265px;
$frame-height: 6.5px;
$frame-width: 8px;

.app-preview-wrapper {
  text-align: left;
  height: $device-height;
  width: 265px;
  position: relative;
  z-index: 0;

  .frame {
    width: 100%;
    height: 100%;
  }

  .app-preview-content {
    position: absolute;
    top: $frame-height;
    left: $frame-width;
    height: $device-height - 2 * $frame-height;
    z-index: -1;
  }
}

$number-size: 64px;

.align-helper {
  position: relative;
  width: $number-size;
  text-align: center;
}

.circle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: table;
  width: $number-size;
  height: $number-size; 

  .circle-content {
    font-size: 1.6em;
    font-weight: bold;
  
    display: table-cell;
    vertical-align: middle;
    
    background-color: $bg-color;
    border-radius: 50%;
    border: 1px solid $border-color;
  }
}
