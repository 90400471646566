@import "src/styles/colors";

.preview {
  margin-top: 200px;

  .app-preview {
    position: relative;

    display: flex;
    justify-content: center;

    $hightlight-size: 62.5%;

    .align-helper {
      position: absolute;
      width: 100%;
      height: 100%;
      display: table;

      text-align: center;
      vertical-align: middle;
    }

    .align-helper-cell {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
    }

    .highlight {
      margin: auto;
      width: $hightlight-size;
      padding-top: $hightlight-size;

      border-radius: 50%;
      background-color: $orange;
    }
  }

  // left column

  .app-preview-text {
    text-align: left;
    border-radius: 8px;

    .item {
      margin-top: 20px;
      padding-left: 15px;

      .align-helper {
        margin-left: 15px;
        margin-right: 10px;
      }

      :hover {
        cursor: pointer;
      }
    }

    .active-item {
      background-image: url('disclosure-left.svg');
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 2%;

      .item-inner {
        background-color: #1A1A1A;
        border: 1px solid #1A1A1A;
      }
    }

    // hack for displaying arrow on active item
    .item-inner {
      border: 1px solid $border-color;
      padding: 25px 10px;
      width: 100%;
      margin: 0;
    }

    $number-size: 64px;

    .circle {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .active-item {
      .circle-content {
        background-color: $orange;
        color: $bg-color;
        border: 0;
        box-shadow: 0px 0px 50px -14px #FFCB57;
      }
    }
  }
}

@media (max-width: 1200px) {

  .preview .app-preview-text .circle {
    padding: 10px;
  }
}

@media (max-width: 990px) {
  .preview .app-preview-text .circle {
    padding: 10px;
  }

  .preview {
    display: none;
  }
}