@import "src/styles/colors";

.privacy-policy {
    text-align: justify;

    .title {
        margin-bottom: 50px;
    }

    h3 {
        margin: 20px 0;
    }

    h4 {
        margin-top: 35px;
        margin-bottom: 15px;
    }

    h5 {
        margin-top: 25px;
        margin-bottom: 12px;
        font-size: 1.3rem;
        font-weight: bold;
    }

    p {
        font-size: 1.125rem;
        color: #b9b9b9;
    }

    a {
        color: $text-muted-color;

        &:hover {
            color: $text-muted-color-hover;
        }
    }
}